<template>
  <div v-if="data" @click="clickToCard" class="application-card card">
    <div v-if="data.imagesSrc" class="card__top" :style="{ backgroundImage: `url(${data.imagesSrc[0]?.fileSrc})` }">
      <div class="card__top-chip">
        <div class="chip" :style="{ backgroundColor: getStatus(data.status).color}" />
        {{ $t(getStatus(data.status).text) }}
      </div>
      <div class="card__top-info">
        <p v-if="data.type" class="info-text">{{ $t('type-number', {data: data.type.name }) }}</p>
        <p v-if="visibleLikes" class="info-text">Баллы: {{ data.likesCount }}</p>
        <p class="info-text">{{ $t('damage-calculation') }}: {{ prettify(+data.damage) || 0 }} ₽</p>
      </div>
    </div>
    <div class="card__bottom">
      <div class="card__bottom-info">
        <p class="info-application">{{ $t('request', {data: data.id}) }}</p>
        <p class="info-date">{{ getFullDate(data.createdAt) }}</p>
      </div>
      <div class="card__bottom-line"></div>
      <div class="card__bottom-location">
        <div class="location-name">
          <img src="@/assets/svg/icon-location.svg" alt="" />
          <p v-if="data.district" class="location-text">{{ data.district.name }}</p>
        </div>
        <p class="rejected-reason" v-if="data.status === 'Rejected' && getUser.role === 'Moderator'">{{ $t('resubmission') }}</p>
      </div>
    </div>
    <p class="card__bind" v-if="isBind" @click="send">{{ $t('bind') }}</p>
    <ui-alert v-model="visibleAlert">
      <p class="ui-alert__title">{{ $t('request-added-to-the-list', {data: data.id}) }}</p>
      <div class="ui-alert__action">
        <ui-button @click="visibleAlert=false">{{ $t('continue') }}</ui-button>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import { getStatus, getFullDate } from '@/utils/general'
import {mapGetters} from "vuex";
import UiAlert from "@/components/ui/UiAlert.vue";
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "WorkRequestsCard",
  components: {UiButton, UiAlert},

  data() {
    return {
      getStatus,
      getFullDate,
      isBind: false,
      visibleAlert: false
    }
  },

  props: {
    data: {
      type: Object,
      default: () => {}
    },
    visibleLikes: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    clickToCard() {
      !this.$route.query.category ? this.$router.push(`/work-requests/${this.data.id}`) : this.isBind = !this.isBind
    },
    prettify(num) {
      let n = num.toString();
      return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ' ');
    },
    send () {
      this.visibleAlert = true
      setTimeout(() => {
        this.isBind = true
      }, 100)
    }
  },

  computed: {
    ...mapGetters(['getUser'])
  },

  // mounted () {
  //   console.log(this.data.imagesSrc)
  // }
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 20px;
  transition: .3s;
  cursor: pointer;
  border: 1px solid transparent;
  height: 100%;
  width: 100%;
  max-width: 320px;
  position: relative;
  box-shadow: 2px 2px 10px 0px rgba(153, 153, 153, 0.20);

  &:hover {
    border: 1px solid #CE2121;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 67px;
    //background-image: url('../../../assets/images/event-card.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 36px 15px 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    min-height: 172px;
    max-height: 173px;

    @media (max-width: 992px) {
      gap: 36px;
      padding: 10px;
    }

    @media (max-width: 768px) {
      min-height: 100px;
      max-height: 105px;
      gap: 20px;
    }

    &-chip {
      width: fit-content;
      background-color: #FFFFFF;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      gap: 5px;
      border-radius: 100px;
      font-family: "Gotham Pro Regular";
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      color: #343432;

      .chip {
        width: 10px;
        height: 10px;
        box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
        border-radius: 200px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .info-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 120.2%;
        color: #FFFFFF;
        font-family: "Gotham Pro Regular";

        @media (max-width: 768px) {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px 36px 15px 20px;

    @media (max-width: 992px) {
      padding: 10px;
      gap: 10px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .info-application {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        color: #E21F1F;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 13px;
        }
      }

      .info-date {
        font-family: "Gotham Pro Regular";
        font-weight: 400;
        font-size: 14px;
        line-height: 120.2%;
        color: #343432;

        @media (max-width: 768px) {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }

    &-line {
      width: 100%;
      border-bottom: 1px solid #F5F5F5;
    }

    &-location {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      @media (max-width: 600px) {
        flex-wrap: wrap;
      }

      .location-name {
        display: flex;
        gap: 5px;
        align-items: center;

        p {
          font-family: "Gotham Pro Regular";
          font-weight: 400;
          font-size: 14px;
          line-height: 120.2%;
          color: #343432;
          word-break: break-word;
          
          @media (max-width: 768px) {
            font-size: 10px;
          }
        }
      }

      .rejected-reason {
        font-family: "Gotham Pro Regular";
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #CE2121;

        @media (max-width: 768px) {
          font-size: 10px;
        }
      }
    }
  }

  &__bind {
    color: #9A9A9A;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    bottom: -24px;
    left: 115px;
  }
}
</style>
